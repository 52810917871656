import React, { useState, useEffect } from 'react';
import { Form, Button, Container, InputGroup } from 'react-bootstrap';

const Registro = () => {
    const [numAros, setNumAros] = useState(6);
    const [puntajeObjetivo, setPuntajeObjetivo] = useState(1000);
    const [numJugadores, setNumJugadores] = useState(0);
    const [nombresJugadores, setNombresJugadores] = useState(['']);

    useEffect(() => {
        const savedData = localStorage.getItem('gameData');
        if (savedData) {
            const { numAros, puntajeObjetivo, numJugadores, nombresJugadores } = JSON.parse(savedData);
            setNumAros(numAros);
            setPuntajeObjetivo(puntajeObjetivo);
            setNumJugadores(numJugadores);
            setNombresJugadores(nombresJugadores);
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            numAros,
            puntajeObjetivo,
            numJugadores,
            nombresJugadores,
        };
        localStorage.setItem('gameData', JSON.stringify(data));
        localStorage.removeItem('puntos');
        localStorage.removeItem('turnos');
        localStorage.removeItem('puntosPorTurno');
        localStorage.removeItem('ranas');
        alert('Datos guardados en LocalStorage');
    };

    const handleNombresChange = (index, value) => {
        const newNombres = [...nombresJugadores];
        newNombres[index] = value;
        setNombresJugadores(newNombres);
    };

    const handleNumJugadoresChange = (value) => {
        setNumJugadores(value);
        const newNombres = Array(parseInt(value)).fill('');
        setNombresJugadores(newNombres);
    };

    const inputRana = (title, ph, value, funcion, type) => {
        return (
            <InputGroup className="mb-3">
                <InputGroup.Text id={title}>{title}</InputGroup.Text>
                <Form.Control
                    placeholder={ph}
                    type={type}
                    aria-label={title}
                    aria-describedby={title}
                    value={value}
                    min={type === 'number' ? 0 : null}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        if (type === 'number') {
                            funcion(newValue === '' ? 0 : parseInt(newValue));
                        } else {
                            funcion(newValue);
                        }
                    }}
                />
            </InputGroup>
        )
    }

    return (
        <Container>
            <h1 className='text-center'>Datos de Juego</h1>
            <Form onSubmit={handleSubmit}>

                {inputRana('Número de aros a lanzar', 'Ingrese el número de aros a lanzar', numAros, setNumAros, 'number')}
                {inputRana('Puntaje objetivo', 'Ingrese el puntaje objetivo', puntajeObjetivo, setPuntajeObjetivo, 'number')}
                {inputRana('Número de jugadores', 'Ingrese el número de jugadores', numJugadores, handleNumJugadoresChange, 'number')}

                {nombresJugadores.map((nombre, index) => (
                    inputRana(
                        `Jugador ${index + 1}`,
                        `Ingrese el nombre del jugador ${index + 1}`,
                        nombre,
                        (value) => handleNombresChange(index, value),
                        'text'
                    )
                ))}
                <div className="d-grid gap-2">
                    <Button variant="primary" type="submit" className='mt-2'>
                        Guardar
                    </Button>
                </div>
            </Form>
        </Container>
    );
};

export default Registro;