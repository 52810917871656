import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Registro from './components/Registro';
import OrdenJuego from './components/OrdenJuego';
import NavigationTabs from './components/NavigationTabs';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import logo from './images/lvup.png';
import rana from './images/rana.png';

const App = () => {
  return (
    <Router>
      <div id="root">
        <header className="bg-dark text-white text-center py-3 mb-4">
          <h1>
            <span> <img src={rana} alt="logo" width="100" className='me-2' /></span>
            <span style={{fontSize: '15px'}}> by <img src={logo} alt="logo" width="60" className='ms-2 pb-4' /></span>
          </h1>
        </header>
        <NavigationTabs />
        <div className="container-fluid main-content">
          <Routes>
            <Route path="/registro" element={<Registro />} />
            <Route path="/orden-juego" element={<OrdenJuego />} />
            <Route path="/" element={<Registro />} />
          </Routes>
        </div>
        <footer className="bg-dark text-white text-center py-3 mt-4">
          <p>&copy; 2024 LivenUP! Games. Todos los derechos reservados LivenUP!.</p>
        </footer>
      </div>
    </Router>
  );
};

export default App;