import React from 'react';
import { Card, Button, Badge, Row, Col, CardFooter } from 'react-bootstrap';

const JugadorCard = ({
    jugador,
    puntos,
    turnos,
    puntosPorTurno,
    index,
    turnoActual,
    jugadorActivo,
    tirosRestantes,
    ganador,
    handleIniciarTurno,
    handleFinalizarTurno,
    handleAddPoints,
    handleMoñona,
    puntosValores,
    ranas,
}) => {
    return (
        <Card className="mb-3">
            <Card.Header>
                <Row>
                    <Col> <Card.Title>{jugador}</Card.Title> </Col>
                    <Col>
                        <Card.Subtitle className="text-muted">Pts: {puntos}</Card.Subtitle>
                        <Card.Text>Turno: {turnos}</Card.Text>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col>
                        {jugadorActivo === index ? (
                            <Button onClick={handleFinalizarTurno} variant="success" disabled={tirosRestantes > 0} className="mb-2 w-100">
                                Finalizar Turno
                            </Button>
                        ) : (
                            <Button onClick={() => handleIniciarTurno(index)} disabled={jugadorActivo !== null || turnos !== turnoActual || ganador !== null} className="mb-2 w-100">
                                Iniciar Turno
                            </Button>
                        )}
                    </Col>
                </Row>
                {puntosValores.map((valor) => (
                    <Button
                        key={valor}
                        onClick={() => handleAddPoints(index, valor)}
                        disabled={jugadorActivo !== index || tirosRestantes <= 0 || ganador !== null}
                        className="mb-2 me-2"
                    >
                        {valor}
                        {[35, 70, 100].includes(valor) && (
                            <Badge pill bg="secondary" style={{ marginLeft: '5px' }}>
                                {ranas[valor]}
                            </Badge>
                        )}
                    </Button>
                ))}
                <Button
                    onClick={() => handleMoñona(index)} disabled={jugadorActivo !== index || tirosRestantes <= 0 || ganador !== null}
                    className="mb-2">
                    Moñona
                </Button>
            </Card.Body>
            <CardFooter>
                {Array.from({ length: turnoActual + 1 }, (_, i) => (
                    <Card.Text key={i} style={{ fontSize: '12px' }} className='p-0 m-0'>
                        Turno {i + 1}: {puntosPorTurno[i] || 0}
                    </Card.Text>
                ))}
            </CardFooter>
        </Card>
    );
};

export default JugadorCard;