import React, { useState, useEffect } from 'react';
import { Container, Table, Button, Badge } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import JugadorCard from './JugadorCard';


const OrdenJuego = () => {
    const [jugadores, setJugadores] = useState([]);
    const [puntos, setPuntos] = useState([]);
    const [turnos, setTurnos] = useState([]);
    const [turnoActual, setTurnoActual] = useState(0);
    const [jugadorActivo, setJugadorActivo] = useState(null);
    const [tirosRestantes, setTirosRestantes] = useState(0);
    const [numAros, setNumAros] = useState(0);
    const [puntajeObjetivo, setPuntajeObjetivo] = useState(0);
    const [ganador, setGanador] = useState(null);
    const [puntosPorTurno, setPuntosPorTurno] = useState([]);
    const [ranas, setRanas] = useState([]);
    const [historial, setHistorial] = useState([]);

    useEffect(() => {
        const savedData = localStorage.getItem('gameData');
        if (savedData) {
            const { nombresJugadores, numAros, puntajeObjetivo } = JSON.parse(savedData);
            setJugadores(nombresJugadores);
            setNumAros(numAros);
            setPuntajeObjetivo(puntajeObjetivo);
            const savedPuntos = localStorage.getItem('puntos');
            const savedTurnos = localStorage.getItem('turnos');
            const savedPuntosPorTurno = localStorage.getItem('puntosPorTurno');
            const savedRanas = localStorage.getItem('ranas');
            if (savedPuntos) {
                setPuntos(JSON.parse(savedPuntos));
            } else {
                setPuntos(Array(nombresJugadores.length).fill(0));
            }
            if (savedTurnos) {
                setTurnos(JSON.parse(savedTurnos));
            } else {
                setTurnos(Array(nombresJugadores.length).fill(0));
            }
            if (savedPuntosPorTurno) {
                setPuntosPorTurno(JSON.parse(savedPuntosPorTurno));
            } else {
                setPuntosPorTurno(Array(nombresJugadores.length).fill([]));
            }
            if (savedRanas) {
                setRanas(JSON.parse(savedRanas));
            } else {
                setRanas(Array(nombresJugadores.length).fill({ 35: 0, 70: 0, 100: 0 }));
            }
        }
    }, []);

    const handleAddPoints = (index, points) => {
        if (jugadorActivo === index && tirosRestantes > 0) {
            const newPuntos = [...puntos];
            newPuntos[index] += points;
            setPuntos(newPuntos);
            localStorage.setItem('puntos', JSON.stringify(newPuntos));
            setTirosRestantes(tirosRestantes - 1);

            const newHistorial = [...historial, { index, points }];
            setHistorial(newHistorial);

            if ([35, 70, 100].includes(points)) {
                const newRanas = [...ranas];
                newRanas[index] = { ...newRanas[index], [points]: newRanas[index][points] + 1 };
                setRanas(newRanas);
                localStorage.setItem('ranas', JSON.stringify(newRanas));
            }
        }
    };

    const handleMoñona = (index) => {
        if (jugadorActivo === index) {
            setGanador(jugadores[index]);
        }
    };

    const handleIniciarTurno = (index) => {
        if (jugadorActivo === null && turnos[index] === turnoActual) {
            setJugadorActivo(index);
            setTirosRestantes(numAros);
        }
    };

    const handleFinalizarTurno = () => {
        const newTurnos = [...turnos];
        newTurnos[jugadorActivo] += 1;
        setTurnos(newTurnos);
        localStorage.setItem('turnos', JSON.stringify(newTurnos));

        const newPuntosPorTurno = [...puntosPorTurno];
        const puntosJugador = puntos[jugadorActivo];
        const puntosPrevios = newPuntosPorTurno[jugadorActivo].reduce((a, b) => a + b, 0) || 0;
        const puntosTurnoActual = puntosJugador - puntosPrevios;

        newPuntosPorTurno[jugadorActivo] = [...newPuntosPorTurno[jugadorActivo]];
        newPuntosPorTurno[jugadorActivo][turnoActual] = puntosTurnoActual;

        setPuntosPorTurno(newPuntosPorTurno);
        localStorage.setItem('puntosPorTurno', JSON.stringify(newPuntosPorTurno));

        setJugadorActivo(null);
        setTirosRestantes(0);

        if (newTurnos.every(turno => turno > turnoActual)) {
            evaluarGanador();
            setTurnoActual(turnoActual + 1);
        }
    };

    const evaluarGanador = () => {
        const maxPuntos = Math.max(...puntos);
        const indexGanador = puntos.indexOf(maxPuntos);
        if (maxPuntos >= puntajeObjetivo) {
            setGanador(jugadores[indexGanador]);
        }
    };

    const handleReiniciarJuego = () => {
        setPuntos(Array(jugadores.length).fill(0));
        setTurnos(Array(jugadores.length).fill(0));
        setTurnoActual(0);
        setJugadorActivo(null);
        setTirosRestantes(0);
        setGanador(null);
        setPuntosPorTurno(Array(jugadores.length).fill([]));
        setRanas(Array(jugadores.length).fill({ 35: 0, 70: 0, 100: 0 }));
        setHistorial([]);
        localStorage.removeItem('puntos');
        localStorage.removeItem('turnos');
        localStorage.removeItem('puntosPorTurno');
        localStorage.removeItem('ranas');
    };

    const handleDeshacer = () => {
        const lastAction = historial.pop();
        if (lastAction) {
            const { index, points } = lastAction;
            const newPuntos = [...puntos];
            newPuntos[index] -= points;
            setPuntos(newPuntos);
            localStorage.setItem('puntos', JSON.stringify(newPuntos));

            if ([35, 70, 100].includes(points)) {
                const newRanas = [...ranas];
                newRanas[index] = { ...newRanas[index], [points]: newRanas[index][points] - 1 };
                setRanas(newRanas);
                localStorage.setItem('ranas', JSON.stringify(newRanas));
            }
            setTirosRestantes(tirosRestantes + 1);
        }
    };

    const puntosValores = [0, 15, 25, 30, 35, 40, 70, 100];
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <Container>
            <h1 className="text-center mb-4">Orden de Juego y Acumulado de Puntos</h1>
            <h2 className="text-center mb-4">Turno Global Actual: {turnoActual}</h2>
            {ganador && <h2 className="text-center text-success">¡{ganador} ha ganado con una Moñona!</h2>}
            {isMobile ? (
                jugadores.map((jugador, index) => (
                    <JugadorCard
                        key={index}
                        jugador={jugador}
                        puntos={puntos[index]}
                        turnos={turnos[index]}
                        puntosPorTurno={puntosPorTurno[index]}
                        index={index}
                        turnoActual={turnoActual}
                        jugadorActivo={jugadorActivo}
                        tirosRestantes={tirosRestantes}
                        ganador={ganador}
                        handleIniciarTurno={handleIniciarTurno}
                        handleFinalizarTurno={handleFinalizarTurno}
                        handleAddPoints={handleAddPoints}
                        handleMoñona={handleMoñona}
                        puntosValores={puntosValores}
                        ranas={ranas[index]}
                    />
                ))
            ) : (
                <Table striped bordered hover responsive className="mb-4 align-middle">
                    <thead>
                        <tr>
                            <th>Jugador</th>
                            <th>Puntos</th>
                            <th>Turno</th>
                            <th>Acciones</th>
                            {Array.from({ length: turnoActual + 1 }, (_, i) => (
                                <th key={i}>Turno {i + 1}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {jugadores.map((jugador, index) => (
                            <tr key={index}>
                                <td>{jugador}</td>
                                <td className='text-center'>{puntos[index]}</td>
                                <td className='text-center'>{turnos[index]}</td>
                                <td>
                                    {jugadorActivo === index ? (
                                        <Button onClick={handleFinalizarTurno}
                                            variant="success"
                                            disabled={tirosRestantes > 0}
                                            className="mb-2 me-2">
                                            Finalizar Turno
                                        </Button>
                                    ) : (
                                        <Button onClick={() => handleIniciarTurno(index)}
                                            disabled={jugadorActivo !== null || turnos[index] !== turnoActual || ganador !== null}
                                            className="mb-2 me-2">
                                            Iniciar Turno
                                        </Button>
                                    )}
                                    {puntosValores.map((valor) => (
                                        <Button
                                            key={valor}
                                            onClick={() => handleAddPoints(index, valor)}
                                            disabled={jugadorActivo !== index || tirosRestantes <= 0 || ganador !== null}
                                            className="mb-2 me-2"
                                        >
                                            {valor}
                                            {[35, 70, 100].includes(valor) && (
                                                <Badge pill bg="secondary" style={{ marginLeft: '5px' }}>
                                                    {ranas[index][valor]}
                                                </Badge>
                                            )}
                                        </Button>
                                    ))}
                                    <Button onClick={() => handleMoñona(index)}
                                        disabled={jugadorActivo !== index || tirosRestantes <= 0 || ganador !== null}
                                        className="mb-2">
                                        Moñona
                                    </Button>
                                </td>
                                {Array.from({ length: turnoActual + 1 }, (_, i) => (
                                    <td key={i} className='text-center'>{puntosPorTurno[index][i] || 0}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            )}
            <div className="text-center">
                <Button onClick={handleDeshacer} variant="warning" disabled={historial.length === 0} className="me-2 mb-2">
                    Deshacer Última Acción
                </Button>
                <Button onClick={handleReiniciarJuego} variant="danger" className="mb-2">
                    Reiniciar Juego
                </Button>
            </div>
        </Container>
    );
};

export default OrdenJuego;