import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const NavigationTabs = () => {
  const location = useLocation();

  return (
    <Nav variant="tabs" activeKey={location.pathname} className="justify-content-center mb-4">
      <Nav.Item>
        <Nav.Link as={Link} to="/registro" eventKey="/registro">
          Registro
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} to="/orden-juego" eventKey="/orden-juego">
          Juegar
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default NavigationTabs;